@font-face {
font-family: '__workSans_5fd2f7';
src: url(/_next/static/media/a819b6f31686ce92-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__workSans_5fd2f7';
src: url(/_next/static/media/9050c43d9184b3f4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__workSans_5fd2f7';
src: url(/_next/static/media/6150033f38ee1e8d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__workSans_Fallback_5fd2f7';src: local("Arial");ascent-override: 82.98%;descent-override: 21.68%;line-gap-override: 0.00%;size-adjust: 112.07%
}.__className_5fd2f7 {font-family: '__workSans_5fd2f7', '__workSans_Fallback_5fd2f7'
}

